var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"width":(_vm.width + "px"),"title":_vm.$t('fAffiliate_Cust_title'),"no-header-close":true,"right":"","shadow":"","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex bg-light align-items-center px-3 py-2"},[_c('strong',{staticClass:"mr-auto"}),_vm._v(" "),_c('b-btn',{attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.onCancel}},[_vm._v("\n        "+_vm._s(_vm.$t('eDoc_form_a_cancel'))+"\n      ")])],1)]},proxy:true}]),model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('b-card',[(_vm.itemId)?_c('data-table',{ref:"dt",staticClass:"flex-grow-1 pt-2 text-nowrap",attrs:{"repo":"affiliates.users","parent-id":_vm.itemId,"fields":_vm.detailFields},scopedSlots:_vm._u([{key:"cell(up.display_name)",fn:function(ref){
var item = ref.data.item;
return [_c('user-profile-item',{attrs:{"user":item.user}})]}},{key:"cell(sending_postback)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[(item.sent_at && item.error)?_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":['fas', 'circle']}}):_vm._e(),_vm._v(" "),(item.sent_at && !item.error)?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fas', 'circle']}}):_vm._e(),_vm._v(" "),(!item.sent_at)?_c('font-awesome-icon',{staticClass:"text-warning",attrs:{"icon":['fas', 'circle']}}):_vm._e()],1)]}},{key:"cell(request)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"small text-left text-wrap",staticStyle:{"max-width":"260px"}},[_vm._v("\n          "+_vm._s(item.request)+"\n        ")])]}},{key:"cell(response)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"small text-left text-wrap",staticStyle:{"max-width":"260px"}},[_vm._v("\n          "+_vm._s(item.response)+"\n        ")])]}},{key:"cell(utm_track)",fn:function(ref){
var item = ref.data.item;
return [(item && item.user && item.user.utm_track)?_c('div',{staticClass:"small text-left text-break"},_vm._l((Object.keys(item.user.utm_track)),function(key){return _c('span',{key:key},[_c('b',{staticClass:"text-uppercase "},[_vm._v(_vm._s(key))]),_vm._v(" : "+_vm._s(item.user.utm_track[key])),_c('br')])}),0):_vm._e()]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }