import { render, staticRenderFns } from "./AffiliateEditForm.vue?vue&type=template&id=15b679ee&scoped=true&"
import script from "./AffiliateEditForm.vue?vue&type=script&lang=js&"
export * from "./AffiliateEditForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b679ee",
  null
  
)

export default component.exports