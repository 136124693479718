<template>
  <div class="item-form-wrapper">
    <b-sidebar
      v-model="state"
      v-click-outside="onClickOutside"
      :width="`${width}px`"
      :title="displayTitle"
      :no-header-close="true"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="px-3 py-2">
        <!-- Item fields -->
        <b-row>
          <b-col class="col-12 col-md-6 col-lg-4">
            <b-form-group
              id="name"
              :label="$t('fAffiliate_f_name')"
              label-for="form-input-name"
            >
              <b-form-input
                id="form-input-name"
                v-model="name"
                trim
                :disabled="isLocked"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-4">
            <b-form-group
              id="slug"
              :label="$t('fAffiliate_f_slug')"
              label-for="form-input-slug"
            >
              <b-form-input
                id="form-input-slug"
                v-model="slug"
                trim
                :disabled="isLocked"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-4">
            <b-form-group
              id="payout"
              :label="$t('fAffiliate_f_payout')"
              label-for="form-input-payout"
            >
              <b-form-input
                id="form-input-payout"
                v-model="payout"
                trim
                :disabled="isLocked"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-2">
            <b-form-group
              id="postback_method"
              :label="$t('fAffiliate_f_postback_method')"
              label-for="form-input-postback_method"
            >
              <b-form-select
                id="form-input-postback_method"
                v-model="postback_method"
                :options="formDataRepo.postback_method"
                label-field="value"
                value-field="id"
                :disabled="isLocked"
              />
            </b-form-group>
          </b-col>
          <b-col class="col-12 col-md-6 col-lg-10">
            <b-form-group
              id="postback_url"
              :label="$t('fAffiliate_f_postback_url')"
              label-for="form-input-postback_url"
            >
              <b-input-group>
                <b-form-input
                  id="form-input-postback_url"
                  v-model="postback_url"
                  trim
                  :disabled="isLocked"
                />
                <b-input-group-append>
                  <b-btn
                    v-if="itemId"
                    variant="outline-primary"
                    size="sm"
                    :disabled="isPostbackDisabled"
                    @click="onToggleTest"
                  >
                    {{ $t('fAffiliate_testBtn') }}
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Detail elements -->
        <b-btn
          v-if="itemId"
          v-shtml="$t('fAffiliate_customers', { count: usersBrought })"
          variant="primary"
          class="mt-2"
          size="sm"
          @click="onToggleCustomers"
        />
        <affiliate-test-form
          v-if="itemId"
          v-model="testState"
          :url="postback_url"
          :idx="idx"
          class="my-2"
          @cancel="onTestFormCancel"
          @send="onTestFormSend"
        />
        <affiliate-customers
          v-if="itemId"
          v-model="customersState"
          :url="postback_url"
          :item-id="itemId"
          :idx="idx"
          class="my-2"
        />
        <affiliate-filters
          v-if="itemId"
          v-model="filters"
          :item-id="itemId"
        />
      </div>
      <template #footer>
        <div class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn
            variant="primary"
            size="sm"
            :disabled="isSubmitDisabled"
            @click="onSubmitClose"
          >
            {{ $t('eDoc_form_a_submitClose') }}
          </b-btn>
          <b-btn
            variant="primary"
            size="sm"
            class="ml-1"
            :disabled="isSubmitDisabled"
            @click="onSubmit"
          >
            {{ $t('eDoc_form_a_submit') }}
          </b-btn>
          <b-btn
            variant="secondary"
            size="sm"
            class="ml-1"
            :disabled="busy"
            @click="onCancel"
          >
            {{ $t('eDoc_form_a_cancel') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { GlobalForm } from '~/mixins/globalForm'
import { affiliateFormFields } from '~/store/forms/affiliateEdit'

const globalForm = GlobalForm('affiliateEdit', affiliateFormFields)

export default {
  ...globalForm,
  name: 'AffiliateEditForm',
  props: {
    ...globalForm.props
  },
  data () {
    return {
      ...globalForm.data(),
      testState: false,
      customersState: false,
      editFields: [
        {
          key: 'name',
          label: this.$t('fAffiliate_f_name'),
          type: 'input',
          class: 'col-12 col-md-6 col-lg-4',
          default: ''
        },
        {
          key: 'slug',
          label: this.$t('fAffiliate_f_slug'),
          type: 'input',
          class: 'col-12 col-md-6 col-lg-4',
          default: ''
        },
        {
          key: 'payout',
          label: this.$t('fAffiliate_f_payout'),
          type: 'input',
          class: 'col-12 col-md-6 col-lg-4',
          default: ''
        },
        {
          key: 'postback_method',
          label: this.$t('fAffiliate_f_postback_method'),
          type: 'select',
          class: 'col-12 col-md-6 col-lg-2',
          default: 'get'
        },
        {
          key: 'postback_url',
          label: this.$t('fAffiliate_f_postback_url'),
          type: 'input',
          class: 'col-12 col-md-6 col-lg-10',
          default: ''
        },
        {
          key: 'filters',
          label: 'filters',
          type: 'input',
          class: 'col-12 col-lg-10',
          default: ''
        }
      ]
    }
  },
  computed: {
    ...globalForm.computed,
    displayTitle () {
      let title
      if (this.title) {
        title = this.title
      } else {
        title = `${
          this.itemId !== null
            ? this.$t('eDoc_form_labelEdit')
            : this.$t('eDoc_form_labelNew')
        } affiliate ${this.itemId !== null ? ` - ID:${this.itemId}` : ''}`
      }
      return title
    },
    isSubmitDisabled () {
      return !!this.busy || Object.keys(this?.dirty || {}).length === 0
    },
    isPostbackDisabled () {
      return this.postback_url === ''
    }
  },
  watch: {
    ...globalForm.watch
  },
  methods: {
    ...globalForm.methods,
    ...mapActions({
      fill: 'forms/affiliateEdit/fill'
    }),
    fillData () {
      if (this.itemRepo && this.itemRepo.id === this.itemId) {
        this.fill({
          id: this.itemId,
          data: {
            ...this.itemRepo,
            formData: this.formDataRepo
          }
        })
      }
    },
    onToggleCustomers () {
      this.customersState = !this.customersState
    },
    onToggleTest () {
      this.testState = !this.testState
    },
    onTestFormCancel () {
      this.testState = false
    },
    onTestFormSend () {
      this.testState = false
    }
  }
}
</script>

<style scoped>

</style>
