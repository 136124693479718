<template>
  <b-sidebar
    v-model="state"
    :width="`${width}px`"
    :title="$t('fAffiliate_Test_title')"
    :no-header-close="true"
    right
    shadow
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card>
      <b-alert :show="!!error" variant="danger">
        <div v-if="error && error.title" class="font-weight-bold">
          {{ error.title }}
        </div>
        <small v-if="error && error.message">{{ error.message }}</small>
      </b-alert>
      <b-row>
        <b-col class="col-3">
          <b-form-group :label="$t('fAffiliate_Test_f_postbackURL')">
            <b-form-input v-model="postbackURL" :disabled="busy" />
          </b-form-group>
        </b-col>
        <b-col class="col-3">
          <b-form-group :label="$t('fAffiliate_Test_f_params')">
            <b-form-input v-model="params" :disabled="busy" />
          </b-form-group>
        </b-col>
        <b-col class="col-3">
          <b-form-group :label="$t('fAffiliate_Test_f_method')">
            <b-form-select
              v-model="method"
              :options="methods"
              :disabled="busy"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-2 d-flex align-items-end justify-content-start">
          <b-btn variant="primary" class="mb-3" :disabled="busy" @click="onSend">
            <span>
              {{ $t('fAffiliate_Test_send') }}
            </span>
            <font-awesome-icon v-if="busy" :icon="['fas', 'circle-notch']" spin />
          </b-btn>
        </b-col>
      </b-row>
      <div v-if="response" class="p-2 bg-secondary text-white" style="overflow: hidden; overflow-y: auto">
        {{ response }}
      </div>
    </b-card>
    <template #footer>
      <div class="d-flex bg-light align-items-center px-3 py-2">
        <strong class="mr-auto" />
        <b-btn variant="secondary" size="sm" @click="onCancel">
          {{ $t('eDoc_form_a_cancel') }}
        </b-btn>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'AffiliateTestForm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    idx: {
      type: Number,
      default: -1
    },
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      methods: [
        {
          value: 'GET',
          text: 'GET'
        },
        {
          value: 'POST',
          text: 'POST'
        }
      ],
      busy: false,
      state: this.value
    }
  },
  computed: {
    ...mapFields({
      postbackURL: 'forms.affiliateTest.postbackURL',
      params: 'forms.affiliateTest.params',
      method: 'forms.affiliateTest.method',
      error: 'forms.affiliateTest.error',
      response: 'forms.affiliateTest.response'
    }),
    width () {
      return !isServer
        ? Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        : 0
    }
  },
  watch: {
    value (n) {
      this.state = n
    },
    url (n) {
      this.postbackURL = n
    }
  },
  mounted () {
    this.postbackURL = this.url
  },
  beforeDestroy () {
    this.flush()
  },
  methods: {
    ...mapActions({
      send: 'forms/affiliateTest/send',
      flush: 'forms/affiliateTest/flush'
    }),
    onCancel () {
      this.$emit('input', false)
    },
    async onSend () {
      try {
        this.busy = true
        await this.send()
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      } finally {
        this.busy = false
      }
    }
  }
}
</script>

<style scoped>

</style>
