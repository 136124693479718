<template>
  <div class="pt-3">
    <div class="w-100 pb-2 flex-column flex-md-row flex-lg-row d-inline-flex justify-content-between">
      <h4>Recognize filters</h4>
      <div v-if="batch" style="width: 240px;">
        <b-progress
          :max="100"
        >
          <b-progress-bar
            :value="batch.progress"
            :label="`${((batch.progress / 100) * 100).toFixed(2)}%`"
            show-progress
            animated
          />
        </b-progress>
      </div>
      <div v-else>
        <p v-if="recognized && !aggregated">
          recognized {{ recognized && recognized.total }} records
        </p>
        <p v-if="aggregated">
          aggregated {{ aggregated }} records
        </p>
      </div>
      <div>
        <b-btn
          variant="primary"
          size="sm"
          @click="onAddFIlter"
        >
          Add
        </b-btn>
        <b-btn
          size="sm"
          @click="onRecognize"
        >
          Recognize
        </b-btn>
        <b-btn
          v-if="recognized && recognized.total > 0 && !batch"
          variant="warning"
          size="sm"
          @click="onAggregateRecognized"
        >
          Aggregate Recognized
        </b-btn>
        <b-btn
          v-if="recognized && recognized.total > 0 && !!batch"
          variant="danger"
          size="sm"
          @click="onCancelAggregateRecognized"
        >
          Stop Aggregate
        </b-btn>
      </div>
    </div>
    <b-table
      striped
      hover
      small
      class="datatable table-responsive"
      :items="filters"
      :fields="fields"
    >
      <template #cell(id)="data">
        <span>{{ (recognized && recognized.total > 0 && parseInt(recognized.items[data.item.id])) || 0 }}</span>
      </template>
      <template #cell(column)="data">
        <b-form-input
          v-model="data.item.column"
          :list="`${data.index}-list-id`"
          style="min-width: 160px"
          @input="onChanged(data.item.id, 'column')"
        />
        <datalist :id="`${data.index}-list-id`">
          <option v-for="(column, i) in columns" :key="i">
            {{ column }}
          </option>
        </datalist>
      </template>
      <template #cell(match)="data">
        <b-form-select
          v-model="data.item.match"
          :options="matches"
          style="min-width: 160px"
          @input="onChanged(data.item.id, 'match')"
        />
      </template>
      <template #cell(value)="data">
        <b-form-input
          v-model="data.item.value"
          :disabled="data.item.match === 'exists' || data.item.match === ''"
          style="min-width: 160px"
          @input="onChanged(data.item.id, 'value')"
        />
      </template>
      <template #cell(actions)="data">
        <b-btn variant="danger" @click="onDelete(data.item.id)">
          <font-awesome-icon :icon="['fas', 'trash']" />
        </b-btn>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { randId } from '~/mixins/helpers'

export default {
  name: 'AffiliateFilters',
  props: {
    value: {
      type: String,
      default: ''
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      timer: null,
      filters: this.value ? JSON.parse(this.value) : []
    }
  },
  computed: {
    ...mapFields('forms/affiliateEdit', {
      recognized: 'recognized',
      aggregated: 'aggregated',
      batch: 'batch'
    }),
    fields () {
      return [
        {
          key: 'column',
          label: 'Column',
          type: 'input',
          class: 'col-3',
          default: ''
        },
        {
          key: 'match',
          label: 'Match',
          type: 'input',
          class: 'col-2',
          default: ''
        },
        {
          key: 'value',
          label: 'Value',
          type: 'input',
          class: 'col-5',
          default: ''
        },
        {
          key: 'id',
          label: 'Recognized',
          type: 'input',
          class: 'col-1 text-right',
          default: ''
        },
        {
          key: 'actions',
          label: '',
          type: 'input',
          class: 'col-1 text-right',
          default: ''
        }
      ]
    },
    columns () {
      return [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_affiliate',
        'referer'
      ]
    },
    matches () {
      return [
        'exists',
        'eq',
        'not_eq',
        'like',
        'not_like'
      ]
    }
  },
  watch: {
    value: {
      handler (n, o) {
        const nv = this.value
        const ov = JSON.stringify(this.filters)
        if (nv !== ov) {
          this.filters = JSON.parse(n)
        }
      },
      deep: true
    },
    batch (v) {
      if (v && !this.timer) {
        this.timer = setInterval(async () => {
          this.$store.dispatch('setLoadingEnable', false, { root: true })
          await this.recognize(this.itemId)
          this.$store.dispatch('setLoadingEnable', true, { root: true })
        }, 3000)
      } else if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  },
  mounted () {
    this.$nextTick(async () => {
      await this.recognize(this.itemId)
      this.$nextTick(() => {
        if (this.batch && !this.timer) {
          this.timer = setInterval(async () => {
            this.$store.dispatch('setLoadingEnable', false, { root: true })
            await this.recognize(this.itemId)
            this.$store.dispatch('setLoadingEnable', true, { root: true })
          }, 3000)
        }
      })
    })
  },
  methods: {
    ...mapActions({
      recognize: 'forms/affiliateEdit/recognize',
      aggregateRecognized: 'forms/affiliateEdit/aggregateRecognized',
      cancelAggregateRecognized: 'forms/affiliateEdit/cancelAggregateRecognized',
      flushAggregated: 'forms/affiliateEdit/flushAggregated'
    }),
    onAddFIlter () {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.push({
        id: randId(),
        column: '',
        match: '',
        value: ''
      })
      this.$emit('input', JSON.stringify(filters))
    },
    onDelete (id) {
      this.filters = this.filters.filter((i) => {
        return i.id !== id
      })
      this.$nextTick(() => {
        this.$emit('input', JSON.stringify(this.filters))
      })
    },
    onChanged (id, column) {
      const valueItem = JSON.parse(this.value).find(i => i.id === id)
      const filterItem = this.filters.find(i => i.id === id)
      if (valueItem[column] !== filterItem[column]) {
        this.$nextTick(() => {
          this.$emit('input', JSON.stringify(this.filters))
        })
      }
    },
    onRecognize () {
      this.flushAggregated()
      this.recognize(this.itemId)
    },
    onAggregateRecognized () {
      this.$confirmDanger({
        title: this.$t('fAffiliate_Conf_title'),
        body: this.$t('fAffiliate_Conf_body'),
        okTitle: this.$t('fAffiliate_Conf_ok'),
        cancelTitle: this.$t('fAffiliate_Conf_cancel')
      })
        .then(async (value) => {
          if (value) {
            try {
              await this.aggregateRecognized(this.itemId)
            } finally {
              //
            }
          }
        })
    },
    onCancelAggregateRecognized () {
      this.cancelAggregateRecognized(this.itemId)
    }
  }
}
</script>

<style scoped>

</style>
